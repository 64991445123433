import { useEffect, useState } from "react";
import useUserRoles from "./useUserRoles";

const useIsAuthorized = (requiredRoles: string[]) => {
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const roles = useUserRoles();

  useEffect(() => {
    let intersection = requiredRoles.filter((role) => roles.includes(role));
    if (requiredRoles.length === 0) {
      setIsAuthorized(true);
    }else if (intersection.length > 0) {
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  }, [roles, requiredRoles]);

  // if (window.location.hostname === "localhost") {
  //   return true;
  // }

  return isAuthorized;
};

export default useIsAuthorized;
