import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import "./CreateGroup.css";
import CloseIcon from "@mui/icons-material/Close";
import { config } from "../../../authConfig";
import { LoadingIndicator } from "../../../Common/UI/components/Loading";
import { CardContainer } from "../../../Common/UI/components/CardContainer";
import useFetchMsal from "../../../Common/UI/hook/useFetchMsal";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import { GroupUserDto } from "../Interfaces";
import PersonIcon from "@mui/icons-material/Person";

export interface SimpleDialogProps {
  open: boolean;
  title: string;
  onClose: () => void;
  children: ReactNode;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, open, children, title } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} className="simple-dialog">
      <DialogTitle>
        <Grid container>
          <Grid item xs={10}>
            <>{title}</>
          </Grid>
          <Grid item xs={2} className="close-icon-container">
            <CloseIcon onClick={onClose} className="close-btn" />
          </Grid>
        </Grid>
      </DialogTitle>
      {children}
    </Dialog>
  );
}

export default function ManageUsers({
  appName,
  groupName,
  refresh,
}: {
  appName: string;
  groupName: string;
  refresh: () => void;
}): ReactElement {
  const execute = useFetchMsal();

  const [open, setOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [groupUsers, setGroupUsers] = useState<GroupUserDto[]>([]);

  function get() {
    setLoading(true);

    const url = config.rootAPIList.groupUsers
      .replace("{applicationName}", appName)
      .replace("{groupName}", groupName);

    execute("GET", `${config.rootAPIUrl}${url}`)
      .then((result) => {
        setGroupUsers(result);
        setLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        handleClose();
      });
  }

  useEffect(() => {
    get();
  }, []);

  function unassignUserGroup(emailToDelete: string) {
    setLoading(true);

    const url = config.rootAPIList.unassignUserFromGroup
      .replace("{applicationName}", appName)
      .replace("{groupName}", groupName)
      .replace("{email}", emailToDelete);

    execute("DELETE", `${config.rootAPIUrl}${url}`)
      .then(() => {
        setLoading(false);
        get();
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        handleClose();
      });
  }

  function assignUserGroup() {
    const payload = {
      applicationName: appName,
      groupName: groupName,
      email: email,
    };

    setLoading(true);

    const url = config.rootAPIList.assignUserToGroup
      .replace("{applicationName}", appName)
      .replace("{groupName}", groupName);

    execute("POST", `${config.rootAPIUrl}${url}`, payload)
      .then(() => {
        setLoading(false);
        get();
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        handleClose();
      });
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEmail("");
    setLoading(false);
  };

  return (
    <Box>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "baseline",
          }}
        >
          <Button
            className={"create-group-btn"}
            onClick={() => {
              handleClickOpen();
            }}
          >
            <>
              <PersonIcon /> Users
            </>
          </Button>
        </Grid>
      </Grid>
      <SimpleDialog
        title={`Manage Users in the "${groupName}" Group`}
        key="create-application-key"
        open={open}
        onClose={handleClose}
      >
        <LoadingIndicator show={loading} >
          <CardContainer className="manage-users-popup">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Grid
                container
                className={"group-item"}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "15px",
                }}
              >
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    required
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  <Button
                    disabled={loading || email.length < 10}
                    onClick={assignUserGroup}
                  >
                    Assign
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <div className="spacer-small"></div>

           
              {groupUsers.map((user) => {
                return (
                  <Grid
                  key={`groupUsers-${user.email}`}
                    container
                    className={"group-item"}
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      paddingY: "15px",
                    }}
                  >
                    <Grid item xs={6}>
                      <Typography>{user.email}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                      <Button
                        disabled={loading}
                        onClick={(ev) => {
                          unassignUserGroup(user.email);
                        }}
                      >
                        Unassign
                      </Button>
                    </Grid>
                  </Grid>
                );
              })}
          </CardContainer>
        </LoadingIndicator>
      </SimpleDialog>
    </Box>
  );
}
