import { AuthenticatedTemplate } from "@azure/msal-react";
import { ReactElement, useContext, useEffect, useState } from "react";
import { ApplicationLocationContext } from "../../../Common/UI/utils/ApplicationState";
import { appRoles, pageNames } from "../../../authConfig";
import useIsAuthorized from "../../../Common/UI/hook/useIsAuthorized";
import { PageContainer } from "../../../Common/UI/components/PageContainer";
import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { CardContainer } from "../../../Common/UI/components/CardContainer";
import useFetchMsal from "../../../Common/UI/hook/useFetchMsal";
import AssignmentTab from "../Assignments/AssignmentTab";
import GroupsTab from "../Group/GroupsTab";
import { ApplicationDto } from "../Interfaces";
import PermissionsTab from "../Permissions/PermissionsTab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


export default function ApplicationHome(): ReactElement {
  const show = useIsAuthorized([appRoles.Authorization.Admin]);
  const { state, setState } = useContext(ApplicationLocationContext);

  useEffect(() => {
    setState({
      path: [pageNames.authorization],
    });
  }, []);

  return (
    <AuthenticatedTemplate>
      <PageContainer>{show ? <DashboardAuthorizationTabsView/> : <></>}</PageContainer>
    </AuthenticatedTemplate>
  );
}

function DashboardAuthorizationTabsView() {
  const { appName } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const execute = useFetchMsal();
  const [application, setApplication] = useState<ApplicationDto | null>();
  const { state, setState } = useContext(ApplicationLocationContext);

  // useEffect(() => {
  //   setState({
  //     path: [pageNames.authorization],
  //   });
  // }, []);

  //tabs
  const [value, setValue] = useState(0);
  const showTabs = useIsAuthorized([appRoles.Authorization.Admin]);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!location.state && !application) {
      //TODO fetchStoresInfo();
    } else if (location.state) {
      setApplication(location.state as ApplicationDto);
    }
  }, [location.state, application]);

  return (
    <CardContainer title={appName + " Authorization"}>
            <Link to={"/authorization"} className="back-to-list-link">
        <ArrowBackIcon />
        Back to applications
      </Link>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label=""
          sx={showTabs ? {} : { display: "none" }}
        >
          <Tab
            label="Permissions"
            id={`simple-tab-1`}
            aria-controls={`simple-tabpanel-1`}
          />
          <Tab
            label="Groups"
            id={`simple-tab-2`}
            aria-controls={`simple-tabpanel-2`}
          />
          <Tab
            label="Permissions Associations"
            id={`simple-tab-3`}
            aria-controls={`simple-tabpanel-3`}
          />
        </Tabs>
      </Box>
      <PermissionsTab value={value} index={0} applicationName={appName!} />
      <GroupsTab value={value} index={1} applicationName={appName!} />
      <AssignmentTab value={value} index={2} applicationName={appName!} />
    </CardContainer>
  );
}
