import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Typography from "@mui/material/Typography";
import { ReactElement, useContext, useEffect, useState } from "react";
import { ApplicationLocationContext } from "../../Common/UI/utils/ApplicationState";
import "./DashboardHome.css";
import useIsAuthorized from "../../Common/UI/hook/useIsAuthorized";
import { appRoles, config, pageNames } from "../../authConfig";
import { PageContainer } from "../../Common/UI/components/PageContainer";
import CreateTicket from "../../Common/UI/components/CreateTicket";
import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Page } from "./Page";
import useFetchMsal from "../../Common/UI/hook/useFetchMsal";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingIndicator } from "../../Common/UI/components/Loading";
import React from "react";

export function DashboardHome(): ReactElement {
  const execute = useFetchMsal();
  const { state, setState } = useContext(ApplicationLocationContext);
  
    useEffect(() => {
      setState({
        path: [pageNames.home],
      });
    }, []);

  const showCreateTicket = useIsAuthorized([
    appRoles.Integration.CreateTickets,
  ]);
  const showRefresh = useIsAuthorized([appRoles.Home.ExecuteManualRefresh]);
  const canRefresh = useIsAuthorized([appRoles.Home.ExecuteManualRefresh]);

  const [loading, setLoading] = useState<boolean>(false);
  const onRefresh = function () {
    if (canRefresh) {
      const url = config.rootAPIList.homeRefresh;
      setLoading(true);
      execute("POST", `${config.rootAPIUrl}${url}`)
        .then((result: string) => {})
          .catch((error) => {

              setLoading(false);
          })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <AuthenticatedTemplate>
        <PageContainer>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: "30px",
            }}
          >
            {showRefresh ? (
              <>
                <Button
                  className={"refresh-btn"}
                  onClick={() => {
                    onRefresh();
                  }}
                  disabled={loading}
                >
                  <>
                    <RefreshIcon sx={{ marginRight: "10px" }} />
                    Refresh
                  </>
                </Button>
              </>
            ) : (
              <></>
            )}
            {showCreateTicket ? (
              <CreateTicket
                buttonTitle={
                  <>
                    <AddIcon sx={{ marginRight: "10px" }} />
                    Create a ticket
                  </>
                }
                className={"home-create-ticket"}
              ></CreateTicket>
            ) : (
              <></>
            )}
          </Box>
          <LoadingIndicator show={loading}>
            <Page />
          </LoadingIndicator>
        </PageContainer>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Typography variant="h6">
          <center>Please sign-in to see your profile information.</center>
        </Typography>
      </UnauthenticatedTemplate>
    </>
  );
}
