import { Box, Grid, Typography } from "@mui/material";
import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { CustomTabPanel } from "../../../Common/UI/components/CustomTabPanel";
import { GridTableRow } from "../../../Common/UI/components/GridTableRow";
import { GridTableHeader } from "../../../Common/UI/components/GridTableHeader";
import {
  ApplicationGraph,
  GroupPermissionDto,
  PermissionDto,
  UserPermissionDto,
} from "../Interfaces";

import useFetchMsal from "../../../Common/UI/hook/useFetchMsal";
import { LoadingIndicator } from "../../../Common/UI/components/Loading";

import EditUserAssignments from "./EditUserAssignments";
import ManageAssignment from "./ManageAssignment";
import { config } from "../../../authConfig";

export default function AssignmentTab({
  value,
  index,
  applicationName,
}: {
  value: number;
  index: number;
  applicationName: string;
}): ReactElement {
  const execute = useFetchMsal();
  const [userPermissions, setUserPermissions] = useState<UserPermissionDto[]>(
    []
  );
  const [groupPermissions, setGroupPermissions] = useState<
    GroupPermissionDto[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [availablePermissions, setAvailablePermissions] = useState<PermissionDto[]>([]);

  useEffect(() => {
    fetchData();
    getAllPermissions();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    const url = config.rootAPIList.applicationGraph.replace(
      "{applicationName}",
      applicationName
    );

    execute("GET", `${config.rootAPIUrl}${url}`)
      .then((result: ApplicationGraph) => {
        setIsLoading(false);
        setGroupPermissions(result.groups);

        const reversedPermissions: UserPermissionDto[] = [];

        const userMap: Record<string, PermissionDto[]> = {};

        result.permissions.forEach(({ permission, users }) => {
          users.forEach((user) => {
            if (!userMap[user]) {
              userMap[user] = [];
            }
            userMap[user].push(permission);
          });
        });

        // Convert the user map to an array of UserPermissionDto objects
        for (const email in userMap) {
          reversedPermissions.push({ email, permissions: userMap[email] });
        }

        setUserPermissions(reversedPermissions);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

    function getAllPermissions() {
      setIsLoading(true);
  
      const url = config.rootAPIList.permissions
        .replace("{applicationName}", applicationName);
  
      execute("GET", `${config.rootAPIUrl}${url}`)
        .then((result) => {
          setAvailablePermissions(result);
          setIsLoading(false);
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
        });
    }

  return (
    <LoadingIndicator show={isLoading}>
      <CustomTabPanel value={value} index={index}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            paddingBottom: "20px",
          }}
        >
          <ManageAssignment applicationName={applicationName} refresh={fetchData} availablePermissions={availablePermissions}/>
        </Box>
        <GridTableHeader>
          <Grid item xs={4} sx={{ fontWeight: "600", fontSize: "18px" }}>
            Name
          </Grid>
          <Grid item xs={4} sx={{ fontWeight: "600", fontSize: "18px" }}>
            Permissions
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              fontWeight: "600",
              fontSize: "18px",
              paddingLeft: "10px",
              textAlign: "right",
            }}
          >
            Actions
          </Grid>
        </GridTableHeader>
        {userPermissions.map((x) => {
          return (
            <GridTableRow key={"user-permissions-" + x.email}>
              <Grid item xs={4}>
                <Typography key={"name-permissions-" + x.email}>
                  {x.email}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                {x.permissions.map((x) => (
                  <Typography key={"perm-" + x.name}>{x.name}</Typography>
                ))}
              </Grid>

              <Grid
                item
                xs={4}
                sx={{
                  paddingLeft: "10px",
                  display: "flex",
                  justifyContent: "right",
                  gap: "10px",
                }}
              >
                <EditUserAssignments
                  appName={applicationName}
                  availablePermissions={availablePermissions}
                  name={x.email}
                  isGroup={false}
                  refresh={fetchData}
                />
              </Grid>
            </GridTableRow>
          );
        })}
        {groupPermissions.map((x) => {
          return (
            <GridTableRow key={"permissions-group-" + x.name}>
              <Grid item xs={4}>
                <Typography>{x.name}</Typography>
              </Grid>
              <Grid item xs={4}>
                {x.permissions.map((x) => (
                  <Typography key={"perm-" + x.name}>{x.name}</Typography>
                ))}
              </Grid>

              <Grid
                item
                xs={4}
                sx={{
                  paddingLeft: "10px",
                  display: "flex",
                  justifyContent: "right",
                  gap: "10px",
                }}
              >
                <EditUserAssignments
                  appName={applicationName}
                  availablePermissions={availablePermissions}
                  name={x.name}
                  isGroup={true}
                  refresh={fetchData}
                />
              </Grid>
            </GridTableRow>
          );
        })}
      </CustomTabPanel>
    </LoadingIndicator>
  );
}
