import { Box, Button, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { ReactElement, useEffect, useState } from "react";
import { CustomTabPanel } from "../../../Common/UI/components/CustomTabPanel";
import { GridTableRow } from "../../../Common/UI/components/GridTableRow";
import { GridTableHeader } from "../../../Common/UI/components/GridTableHeader";
import { PermissionDto } from "../Interfaces";
import { config } from "../../../authConfig";
import useFetchMsal from "../../../Common/UI/hook/useFetchMsal";
import CreatePermission from "./CreatePermission";

import { LoadingIndicator } from "../../../Common/UI/components/Loading";
import EditPermission from "./EditPermission";
import DeleteConfirmation from "../DeleteConfirmation";

export default function PermissionsTab({
  value,
  index,
  applicationName,
}: {
  value: number;
  index: number;
  applicationName: string;
}): ReactElement {
  const execute = useFetchMsal();
  const [permissions, setPermissions] = useState<PermissionDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchStoresInfo();
  }, []);

  const fetchStoresInfo = async () => {
    try {
      setIsLoading(true);
      const url = config.rootAPIList.permissions.replace(
        "{applicationName}",
        applicationName
      );
      const response: PermissionDto[] = await execute(
        "GET",
        `${config.rootAPIUrl}${url}`
      );

      setPermissions(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteHandler = async (item: { name: string }) => {
    try {
      setIsLoading(true);
      const url = config.rootAPIList.deletePermission
        .replace("{applicationName}", applicationName)
        .replace("{permissionName}", item.name);

      await execute("DELETE", `${config.rootAPIUrl}${url}`);

      fetchStoresInfo();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoadingIndicator show={isLoading}>
      <CustomTabPanel value={value} index={index}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            paddingBottom: "20px",
          }}
        >
          <CreatePermission
            appName={applicationName}
            refresh={fetchStoresInfo}
          />
        </Box>
        <GridTableHeader>
          <Grid item xs={3} sx={{ fontWeight: "600", fontSize: "18px" }}>
            Name
          </Grid>
          <Grid
            item
            xs={5}
            sx={{ fontWeight: "600", fontSize: "18px", paddingLeft: "10px" }}
          >
            Description
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              fontWeight: "600",
              fontSize: "18px",
              paddingLeft: "10px",
              textAlign: "right",
            }}
          >
            Actions
          </Grid>
        </GridTableHeader>
        {permissions.map((x) => {
          return (
            <GridTableRow key={"row" + x.name}>
              <Grid item xs={3}>
                <Typography>{x.name}</Typography>
              </Grid>
              <Grid item xs={5} sx={{ paddingLeft: "10px" }}>
                <Typography>{x.description}</Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  paddingLeft: "10px",
                  display: "flex",
                  justifyContent: "right",
                  gap: "10px",
                }}
              >
                <EditPermission
                  appName={applicationName}
                  descriptionName={x.description!}
                  permissionName={x.name}
                  refresh={fetchStoresInfo}
                />

                <DeleteConfirmation
                  deleteHandler={deleteHandler}
                  message={`Are you sure you want to delete permission ${x.name}`}
                  item={x}
                />
              </Grid>
            </GridTableRow>
          );
        })}
      </CustomTabPanel>
    </LoadingIndicator>
  );
}
