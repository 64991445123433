import { ReactElement, useEffect, useState } from "react";
import useIsAuthorized from "../../Common/UI/hook/useIsAuthorized";
import { appRoles, config } from "../../authConfig";
import { FirstRow } from "./FirstRow";
import { SecondRow } from "./SecondRow";
import dayjs from "dayjs";
import useFetchMsal from "../../Common/UI/hook/useFetchMsal";
import { CardFreeInfo, POSStatus, UberEats, WANStatuses } from "./Interfaces";
import React from "react";

export const Page = function (): ReactElement {
  const execute = useFetchMsal();

  const [loadingWANStatuses, setLoadingWANStatuses] = useState<boolean>(true);
  const [WANStatuses, setWANStatuses] = useState<WANStatuses[]>([]);
  const [loadingCardFreeStatuses, setLoadingCardFreeStatuses] =
    useState<boolean>(true);
  const [storeCardFreeStatuses, setStoreCardFreeStatuses] = useState<
    CardFreeInfo[]
  >([]);
  const [loadingPOSStatuses, setLoadingPOSStatuses] = useState<boolean>(true);
  const [storePOSStatuses, setStorePOSStatuses] = useState<POSStatus[]>([]);
  const [loadingUberStatuses, setLoadingUberStatuses] = useState<boolean>(true);
  const [uberStatuses, setUberStatuses] = useState<UberEats[]>([]);

  const showCardFreeInfo = useIsAuthorized([appRoles.Home.CardFreeStatus]);
  const showWANInfo = useIsAuthorized([appRoles.Home.FirewallStatus]);
  const showPOSStatus = useIsAuthorized([appRoles.Home.ViewPOSStatus]);
  const showUberStatus = useIsAuthorized([appRoles.Home.ViewUberEatsStatus]);

  const loadWAN = function (date: string) {
    if (showWANInfo) {
      const url = config.rootAPIList.storeWANStatus;
      setLoadingWANStatuses(true);
      execute("GET", `${config.rootAPIUrl}${url}`)
        .then((result: WANStatuses[]) => {
          setWANStatuses(result);
        })
        .catch((error) => {
          setWANStatuses([]);
        })
        .finally(() => {
          setLoadingWANStatuses(false);
        });
    }
  };

  const loadCardFree = function () {
    if (showCardFreeInfo) {
      const urlCF = config.rootAPIList.cardFree;
      setLoadingCardFreeStatuses(true);
      execute("GET", `${config.rootAPIUrl}${urlCF}`)
        .then((result: CardFreeInfo[]) => {
          setStoreCardFreeStatuses(result);
        })
        .catch((error) => {
          setStoreCardFreeStatuses([]);
        })
        .finally(() => {
          setLoadingCardFreeStatuses(false);
        });
    }
  };

  const loadPOS = function () {
    if (showPOSStatus) {
      const url = config.rootAPIList.deviceSummary;
      setLoadingPOSStatuses(true);
      execute("GET", `${config.rootAPIUrl}${url}`)
        .then((result: POSStatus[]) => {
          setStorePOSStatuses(result);
        })
        .catch((error) => {
          setStorePOSStatuses([]);
        })
        .finally(() => {
          setLoadingPOSStatuses(false);
        });
      // setStorePOSStatuses([
      //     {
      //         "location": "0015",
      //         "activeDevices": 0,
      //         "inactiveDevices": 3
      //     },
      //     {
      //         "location": "0014",
      //         "activeDevices": 0,
      //         "inactiveDevices": 0
      //     },
      //     {
      //         "location": "0102",
      //         "activeDevices": 0,
      //         "inactiveDevices": 0
      //     },
      //     {
      //         "location": "001",
      //         "activeDevices": 1,
      //         "inactiveDevices": 3
      //     },
      //     {
      //         "location": "0012",
      //         "activeDevices": 2,
      //         "inactiveDevices": 0
      //     },
      //     {
      //         "location": "0003",
      //         "activeDevices": 2,
      //         "inactiveDevices": 0
      //     }
      //     ]);
    }
  };

  const loadUberEats = function () {
    if (showUberStatus) {
      const urlCF = config.rootAPIList.uberEats;
      setLoadingUberStatuses(true);
      execute("GET", `${config.rootAPIUrl}${urlCF}`)
        .then((result) => {
          setUberStatuses(result);
        })
        .catch((error) => {
          setUberStatuses([]);
        })
        .finally(() => {
          setLoadingUberStatuses(false);
        });
    }
  };

  useEffect(() => {
    loadCardFree();
  }, [showCardFreeInfo]);

  useEffect(() => {
    loadWAN(dayjs().format("YYYY-MM-DD"));
  }, [showWANInfo]);

  useEffect(() => {
    loadPOS();
  }, [showPOSStatus]);

  useEffect(() => {
    loadUberEats();
  }, [showUberStatus]);

  return (
    <>
      <FirstRow
        loadingWANStatuses={loadingWANStatuses}
        WANStatuses={WANStatuses}
        loadingCardFreeStatuses={loadingCardFreeStatuses}
        cardFreeStatuses={storeCardFreeStatuses}
        loadingPOSStatuses={loadingPOSStatuses}
        POSStatuses={storePOSStatuses}
        uberEatsStatuses={uberStatuses}
        loadingUberEatsStatuses={loadingUberStatuses}
      ></FirstRow>
      <SecondRow
        loadingWANStatuses={loadingWANStatuses}
        WANStatuses={WANStatuses}
        loadingCardFreeStatuses={loadingCardFreeStatuses}
        cardFreeStatuses={storeCardFreeStatuses}
        loadingPOSStatuses={loadingPOSStatuses}
        POSStatuses={storePOSStatuses}
        loadingUberEatsStatuses={loadingUberStatuses}
        uberEatsStatuses={uberStatuses}
      ></SecondRow>
    </>
  );
};
