import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
} from "@mui/material";
import { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import "./Assignments.css";
import CloseIcon from "@mui/icons-material/Close";
import { config } from "../../../authConfig";
import { LoadingIndicator } from "../../../Common/UI/components/Loading";
import { CardContainer } from "../../../Common/UI/components/CardContainer";
import useFetchMsal from "../../../Common/UI/hook/useFetchMsal";
import React from "react";
import { GroupUserDto, PermissionDto, UserPermissionDto } from "../Interfaces";
import EditIcon from "@mui/icons-material/Edit";

export interface SimpleDialogProps {
  open: boolean;
  title: string;
  onClose: () => void;
  children: ReactNode;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, open, children, title } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} className="simple-dialog">
      <DialogTitle>
        <Grid container>
          <Grid item xs={10}>
            <>{title}</>
          </Grid>
          <Grid item xs={2} className="close-icon-container">
            <CloseIcon onClick={onClose} className="close-btn" />
          </Grid>
        </Grid>
      </DialogTitle>
      {children}
    </Dialog>
  );
}

export default function EditUserAssignments({
  appName,
  availablePermissions,
  name,
  isGroup,
  refresh
}: {
  appName: string;
  availablePermissions:PermissionDto[];
  name: string;
  isGroup: boolean;
  refresh:()=>void;
}): ReactElement {
  const execute = useFetchMsal();

  const [open, setOpen] = useState<boolean>(false);
  const [currentItemPermissions, setCurrentItemPermissions] = useState<
    PermissionDto[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedPermission, setSelectedPermission] = useState<
    string | undefined
  >(undefined);

  function getCurrentPermissions() {
    setLoading(true);

    var url = "";
    if (!isGroup) {
      url = config.rootAPIList.userPermissions
        .replace("{applicationName}", appName)
        .replace("{email}", name);
    } else {
      url = config.rootAPIList.groupPermissions
        .replace("{applicationName}", appName)
        .replace("{groupName}", name);
    }

    execute("GET", `${config.rootAPIUrl}${url}`)
      .then((result) => {
        setCurrentItemPermissions(result);
        setLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        handleClose();
      });
  }

  useEffect(() => {
    getCurrentPermissions();
  }, []);

  function unassignPermissionHandler(permToDel: string) {
    setLoading(true);

    var url = "";
    if (isGroup) {
      url = config.rootAPIList.deleteGroupPermission
        .replace("{applicationName}", appName)
        .replace("{groupName}", name)
        .replace("{permissionName}", permToDel);
    } else {
      url = config.rootAPIList.unassignPermissionFromUser
        .replace("{applicationName}", appName)
        .replace("{email}", name)
        .replace("{permissionName}", permToDel);
    }

    execute("DELETE", `${config.rootAPIUrl}${url}`)
      .then(() => {
        setLoading(false);
        getCurrentPermissions();
        refresh();
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        handleClose();
      });
  }

  function assignPermissionHandler() {
    setLoading(true);
    var payload = {};
    var url = "";

    if (isGroup) {
      payload = {
        applicationName: appName,
        groupName: name,
        permission: selectedPermission,
      };

      url = config.rootAPIList.createGroupPermission
        .replace("{applicationName}", appName)
        .replace("{groupName}", name);
    } else {
      payload = {
        applicationName: appName,
        permissionName: selectedPermission,
        email: name,
      };
      url = config.rootAPIList.assignPermissionToUser
        .replace("{applicationName}", appName)
        .replace("{email}", name);
    }

    execute("POST", `${config.rootAPIUrl}${url}`, payload)
      .then(() => {
        setLoading(false);
        getCurrentPermissions();
        refresh();
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        handleClose();
      });
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPermission("");
    setLoading(false);
  };

  return (
    <Box>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "baseline",
          }}
        >
          <Button
            className={"create-group-btn"}
            onClick={() => {
              handleClickOpen();
            }}
          >
            <>
              <EditIcon /> Edit
            </>
          </Button>
        </Grid>
      </Grid>
      <SimpleDialog
        title={
          isGroup === true
            ? `Manage "${name}" Group Permissions`
            : `Manage "${name}" Permissions`
        }
        key="create-application-key"
        open={open}
        onClose={handleClose}
      >
        <LoadingIndicator show={loading}>
          <CardContainer className="manage-users-popup">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Grid
                container
                className={"group-item"}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "15px",
                }}
              >
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="perm-label">Permission</InputLabel>
                    <Select
                      labelId="perm-label"
                      id="perm-type"
                      value={selectedPermission || ""}
                      label="Permission"
                      onChange={(event: SelectChangeEvent) => {
                        setSelectedPermission(event.target.value);
                      }}
                    >
                      {availablePermissions.map((x) => (
                        <MenuItem key={"select-" + x.name} value={x.name}>
                          {x.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  <Button
                    disabled={loading || !selectedPermission}
                    onClick={assignPermissionHandler}
                  >
                    Assign
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <div className="spacer-small"></div>
            <Grid
                container
                className={"group-item"}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "15px",
                }}
              >
            {currentItemPermissions.map((permission) => {
              return (
                <Grid
                  key={`current-item-${permission.name}`}
                  container
                  className={"group-item"}
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    paddingY: "15px",
                  }}
                >
                  <Grid item xs={6}>
                    <Typography>{permission.name}</Typography>
                  </Grid>
                 
                  <Grid item xs={6} sx={{ textAlign: "right" }}>
                    <Button
                      disabled={loading}
                      onClick={(ev) => {
                        unassignPermissionHandler(permission.name);
                      }}
                    >
                      Unassign
                    </Button>
                  </Grid>
                </Grid>
              );
            })}
            </Grid>
          </CardContainer>
        </LoadingIndicator>
      </SimpleDialog>
    </Box>
  );
}
